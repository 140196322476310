<template>
  <div>
    <div class="card">
      <div class="bg-blue-light px-1 rounded pb-2">
        <TitleButton
            class="mt-1"
            btnTitle="List"
            :showBtn="true"
            :showAddNew="false"
            :title="(projectId === '') ? 'Project Kick-off' : 'Update Project Kick-off'"
            @onClickCloseButton="navigateToListPage"
        />

        <div class="row mt-2 gy-1" v-if="selectedSO">
          <div class="row mt-1">
            <div class="col-md-5">
              <div class="row">
                <div class="col-sm-6">
                  <label class="align-middle">Client Name</label>
                  <input :value="selectedSO.contact.name" type="text" readonly class="form-control">
                </div>
                <div class="col-sm-6">
                  <label class="align-middle">Service Names</label>
                  <input :value="getServiceNames" type="text" readonly class="form-control">
                </div>
                <div class="col-sm-8">
                  <label class="align-middle">Project Name</label>
                  <input type="text" class="form-control" v-model="formData.name">
                </div>
                <div class="col-sm-4">
                  <label class="align-middle">Project Code</label>
                  <input type="text" class="form-control" v-model="formData.code">
                </div>
                <div class="col-sm-6">
                  <label class="align-middle">Business Unit</label>
                  <v-select
                      placeholder="Select Business"
                      v-model="formData.business_id"
                      :options="businessLines"
                      label="name"
                      :reduce="name => name.id"
                  />
                </div>
                <div class="col-sm-6">
                  <label class="align-middle">Interval</label>
                  <v-select
                      placeholder="Select Interval"
                      v-model="formData.interval"
                      :options="intervals"
                      label="name"
                      :reduce="name => name.id"
                  />
                  <span v-if="errors.hasOwnProperty('interval')"
                        class="invalid-feedback d-block qcont">{{ errors.interval[0] }}</span>
                </div>
              </div>
            </div>
            <div class="col-md-7">
              <div class="row">
                <div class="col-sm-4">
                  <label class="align-middle">Order Number</label>
                  <input :value="selectedSO.bill_number" type="text" readonly class="form-control">
                </div>
                <div class="col-sm-4">
                  <label class="align-middle">Order Date</label>
                  <input :value="selectedSO.date" type="text" readonly class="form-control">
                </div>
                <div class="col-sm-4">
                  <label class="align-middle">Create Date</label>
                  <input type="date" class="form-control" v-model="formData.date">
                </div>
                <div class="col-sm-4">
                  <label class="align-middle">Contact Owner</label>
                  <input readonly type="text" class="form-control" :value="selectedSO.contact.contact_owner && selectedSO.contact.contact_owner.full_name">
                </div>
                <div class="col-sm-4">
                  <label class="align-middle">Key Account Manager</label>
                  <input readonly type="text" class="form-control" :value="selectedSO.contact.key_account_manager && selectedSO.contact.key_account_manager.full_name">
                </div>
                <div class="col-sm-4">
                  <div class="d-flex align-items-center justify-content-between filter-label">
                    <label for="colFormLabel" class="col-form-label">Project Director: </label>
                    <span class="filter-search-icon cursor-pointer" @click="onOpenContactSearchModal()">
                      <i class="fas fa-search"></i>
                    </span>
                  </div>
                  <AsyncSelect
                      placeholder="Select Project Director"
                      v-model="formData.project_director"
                      :api-service="fetchContactProfiles"
                      :additional-query="{ with_mobile: 1, roles: 'project_director' }"
                      :format-label="formatPatientLabel"
                  />
                </div>
                <div class="col-sm-4">
                  <label class="align-middle">Start Date</label>
                  <input type="date" class="form-control" v-model="formData.start_date">
                </div>
                <div class="col-sm-4">
                  <label class="align-middle">End Date</label>
                  <input type="date" class="form-control" v-model="formData.end_date">
                </div>
                <div class="col-sm-4">
                  <label class="align-middle">Compliance Deadline</label>
                  <input type="date" class="form-control" v-model="formData.compliance_deadline">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="px-1 rounded pb-2">
        <TitleButton
            class="mt-1"
            btnTitle="Add New"
            :showBtn="false"
            :showAddNew="true"
            title="Team Member"
            @onClickAddNewButton="showNewTeamMemberModal"
        />

        <TeamMemberList
            @removeItem="removeItem"
            :projectTeams="formData.project_teams"
        />

      </div>
    </div>

    <div class="card">
      <div class="px-1 rounded pb-2" v-if="formData.id">
        <TitleButton
            class="mt-1"
            btnTitle="Add New"
            :showBtn="false"
            :showAddNew="true"
            title="Project Accounts"
            @onClickAddNewButton="onClickNewAccountGroupModal"
        >
        </TitleButton>

        <div class="row gy-1">
          <section id="basic-tabs-components">
            <div class="row match-height">
              <div class="col-12">
                <ul class="nav nav-tabs" role="tablist">
                  <li
                      class="nav-item"
                      :class="`${accountType == i ? 'active' : ''}`"
                      v-for="(tab, i) in tabs"
                      :key="i"
                      @click="onClickTab(i, tab)"
                      type="button"
                  >
                    <a :class="`${accountType == i ? 'active' : ''}`" class="nav-link" data-bs-toggle="tab"
                       aria-controls="home" role="tab" aria-selected="true">{{ tab }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </div>

      </div>

      <div class="card-body">
        <div class="row d-flex flex-wrap justify-content-between align-items-center">
          <TreeBrowser
              :style="{opacity: chartLoading ? .5 : 1}"
              v-for="(item, i) in projectAccountList"
              :key="i"
              :node="item"
          />
        </div>
      </div>

      <div class="mt-2 d-flex justify-content-start justify-content-sm-center px-2 gap-1 flex-wrap pb-2">
        <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="handleSubmit(true)"
                class="btn btn-primary">
          Save
        </button>
        <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="handleSubmit(false)"
                class="btn btn-primary">Save & New
        </button>
        <button @click="navigateToListPage" class="btn btn-outline-secondary">Cancel</button>
      </div>
      <GlobalLoader/>
    </div>

    <TeamMemberModal
        :extra-query="`&with_link_user=true`"
        :estTimeEnable="true"
        modalTitle="Add Team Member"
        ref="teamMember"
        @onSubmitForm="handleTeamMemberSubmit"
    />
    <AccountGroupModal
        :modal-title="accountTypeName"
        ref="accountGroup"
        :query="getQuery()"
        :project-account-level="projectAccountLevel"
        :types="homeContent.project_account_type"
        :account-type="accountType"
        :project-id="formData.id"
        :showParent="showParent"
        :form="accountGroupForm"
        :accounts="allAccountGroups"
        @onAccountSave="handleProjectAccountSubmit"
        @onCloseGroupModal="resetAccountModalForm"
    />
    <ContactDropdownFilter
        v-if="store.state.isModalOpenThree"
        @onSearchContact="onSearchContact"
    />
  </div>
</template>
<script setup>
import {computed, inject, onMounted, ref, watch} from 'vue';
import {useRoute, useRouter} from "vue-router";
import TitleButton from '@/components/atom/TitleButton';
import handleProjects from "@/services/modules/procurement/project";
import TeamMemberModal from "@/components/molecule/procurement/project/TeamMemberModal";
import TeamMemberList from "@/components/molecule/procurement/project/TeamMemberTableList.vue";
import handleInventory from "@/services/modules/inventory";
import TreeBrowser from "@/components/molecule/procurement/project/TreeBrowser";
import AccountGroupModal from "@/components/molecule/procurement/project/AccountGroupModal";
import handleBusinessesLocations from "@/services/modules/businessesLocations";
import handlePurchase from "@/services/modules/purchase";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import ContactDropdownFilter from "@/components/atom/Contact/ContactDropdownFilter.vue";
import {useStore} from "vuex";
import handleContact from "@/services/modules/contact";
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";

const store = useStore()
const router = useRouter();
const route = useRoute();
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const emitter = inject('emitter');

let teamMember = ref(null);
let accountGroup = ref(null);
let loader = ref(false);
let saveButtonLoader = ref(false);
let saveNewButtonLoader = ref(false);
let businessLines = ref([]);
let accountType = ref('item');
let accountTypeName = ref('Item');
let projectAccountList = ref([]);
let homeContent = ref({});
let showParent = ref(false);
let allAccountGroups = ref([]);
let warehouses = ref([])
let projectAccountLevel = ref('group');
let errors = ref({});
let {fetchHome, chartLoading} = handleInventory();
const {fetchBusinessLocationsList} = handleBusinessesLocations();

const {fetchContactProfiles} = handleContact()
const {formatPatientLabel} = useAsyncDropdownHelper();
const {fetchSalesOrderById} = handlePurchase();
const selectedSO = ref('')
const getServiceNames = ref('')
const onOpenContactSearchModal = () => {
  store.state.isModalOpenThree = true;
}

const onSearchContact = (contact) => {
  formData.value.project_director = contact
  formData.value.project_director_id = contact.id
}

const removeItem = (itemIndex) => {
  formData.value.project_teams.splice(itemIndex, 1);
}

let {
  fetchBusinesses,
  createNewProject,
  fetchProjectForm,
  fetchProjectAccountTree,
  createProjectAccountGroup,
  createProjectAccount,
  updateProjectAccountGroup,
  updateProjectAccount,
  fetchProjectGroups,
  fetchProjectAccount,
  updateProject,
} = handleProjects();
let formData = ref({
  name: null,
  code: null,
  date: null,
  project_director: null,
  sales_order_master_id: null,
  project_director_id: null,
  compliance_deadline: null,
  booked_sale_generals: null,
  business_id: null,
  business_location_id: null,
  start_date: null,
  end_date: null,
  party_id: null,
  interval: null,
  project_teams: [],
})
let accountGroupForm = ref({
  linked_item_id: "",
  linked_account_id: "",
  name: "",
  code: "",
  description: "",
  exp_account_type: "",
  project_account_id: "",
  project_account_type: "",
  fd6_account_id: "",
});

function navigateToListPage() {
  router.push({name: `pending-project-initiate`, params: route.params, query: route.query});
}

function navigateToEditPage(id = null) {
  route.params.projectId = id;
  router.push({name: `edit-project-initiate`, params: route.params, query: route.query});
}

function handleSubmit(redirect = false) {
  saveButtonLoader.value = !!redirect;

  formData.value.project_director_id = formData.value.project_director ? formData.value.project_director.id : ''
  let res = null;
  if (projectId.value) {
    res = updateProject(projectId.value, getQuery(), formData.value);
  } else {
    res = createNewProject(getQuery(), formData.value);
  }

  res.then(res => {
    saveButtonLoader.value = false
    saveNewButtonLoader.value = false

    if (!res.status) {
      return showError(res.message)
    }

    showSuccess(res.message)
    if (redirect && !route.params.projectId) navigateToEditPage(res.data.id)
    if (!route.params.projectId) resetForm()

  }).catch(err => {
    errors.value = err.response.data.errors;
    showError(err.response.data.message);
    saveButtonLoader.value = false;
    saveNewButtonLoader.value = false;
  })
}

function showNewTeamMemberModal() {
  teamMember.value && teamMember.value.toggleModal()
}

function showAddAccountModal() {
  accountGroup.value && accountGroup.value.toggleModal()
}

function onClickTab(tab, name) {
  accountType.value = tab;
  accountTypeName.value = name;
  projectAccountList.value = [];
  getAccountTree();
  getProjectGroups();
}

function handleTeamMemberSubmit(newTeamMember) {
  const find = formData.value.project_teams.findIndex(el => el.team_member_id == newTeamMember.team_member_id);
  if (find >= 1) {
   return formData.value.project_teams[find] = newTeamMember;
  }

  formData.value.project_teams.push(newTeamMember);
}

function getQuery() {
  let query = `?company_id=${route.params.companyId}`;
  query += `&from_project=1`
  if (accountType.value) {
    query += `&account_type=${accountType.value.toLowerCase()}`;
  }
  return query;
}

function resetForm() {
  formData.value = {
    name: null,
    code: null,
    date: null,
    business_id: null,
    business_location_id: null,
    start_date: null,
    end_date: null,
    party_id: null,
    project_teams: [],
  }
}

function resetAccountModalForm() {
  accountGroupForm.value = {
    linked_item_id: "",
    linked_account_id: "",
    name: "",
    code: "",
    description: "",
    exp_account_type: "",
    project_account_id: "",
    project_account_type: "",
  };
  showParent.value = false;
  projectAccountLevel.value = 'group';
}

async function getAccountTree() {
  const res = await fetchProjectAccountTree(projectId.value, getQuery());

  if (res.status) {
    projectAccountList.value = res.data;
  }
}

async function getProjectGroups() {
  const res = await fetchProjectGroups(projectId.value, getQuery());
  if (res.data) {
    allAccountGroups.value = res.data;
  }
}

function onClickNewAccountGroupModal() {
  showAddAccountModal();
  projectAccountLevel.value = 'group';
  accountGroupForm.value.exp_account_type = 'expense';
}

 function handleAccountSave() {
  if (!accountGroupForm.value.id) {
    return createProjectAccount(getQuery(), accountGroupForm.value);
  }
  return updateProjectAccount(accountGroupForm.value.id, getQuery(), accountGroupForm.value);
}

 function handleGroupSave() {
  if (!accountGroupForm.value.id) {
    return createProjectAccountGroup(getQuery(), accountGroupForm.value);
  }
  return updateProjectAccountGroup(accountGroupForm.value.id, getQuery(), accountGroupForm.value);
}

async function handleProjectAccountSubmit() {
  let res = null;
  accountGroupForm.value.project_id = projectId;
  if (projectAccountLevel.value == 'group') {
    res = await handleGroupSave();
  } else {
    res = await handleAccountSave();
  }

  if (!res.status) {
    return showError(res.message);
  }

  showSuccess(res.message);
  resetAccountModalForm();
  showAddAccountModal();
  await getAccountTree();
  await getProjectGroups();
}

const tabs = computed(() => {
  return homeContent.value.project_account_type;
})

const projectId = computed(() => {
  return route.params.projectId ? route.params.projectId : '';
});

const intervals = computed(() => {
  let intervals = [];
  if (homeContent.value.hasOwnProperty('project_intervals')) {
    Object.keys(homeContent.value.project_intervals).forEach(id => {
      intervals.push({
        id: id,
        name: homeContent.value.project_intervals[id],
      })
    })
  }
  return intervals;
})


emitter.on('onClickAddGroup', (event) => {
  accountGroupForm.value.project_account_id = event.id;
  accountGroupForm.value.project_account_type = event.project_account_type;
  showParent.value = true;
  projectAccountLevel.value = 'group';
  accountGroup.value && accountGroup.value.toggleModal()
});

emitter.on('onGroupUpdate', async ({data}) => {
  const res = await fetchProjectAccount(data.id, getQuery());
  if (res.status) {
    accountGroupForm.value = res.data;
  }

  if (data.group_level != 1) {
    showParent.value = true;
  }
  projectAccountLevel.value = 'group';
  showAddAccountModal();
});

emitter.on('onClickAddAccount', (event) => {
  accountGroupForm.value.project_account_id = event.id
  accountGroupForm.value.project_account_type = event.project_account_type
  showParent.value = true
  projectAccountLevel.value = 'account';
  accountGroup.value && accountGroup.value.toggleModal()
})

emitter.on('onAccountUpdate', async ({data}) => {
  const res = await fetchProjectAccount(data.id, getQuery());
  if (res.status) {
    accountGroupForm.value = res.data;
  }
  showParent.value = true
  projectAccountLevel.value = 'account';
  showAddAccountModal();
})

onMounted(async () => {
  const query = getQuery();

  loader.value = true;
  const businessRes = fetchBusinesses(getQuery());
  const homeRes = fetchHome();

  await Promise.all([
    fetchBusinessLocationsList(query).then(res => {
      if (res.data) {
        warehouses.value = res.data;
      }
    }),
    homeRes.then(res => {
      if (res.data) {
        homeContent.value = res.data;
      }
    }),
    businessRes.then(res => {
      if (res.data) {
        businessLines.value = res.data;
      }
    }),
  ]).then(() => {
    loader.value = false
  }).catch((err) => {
    loader.value = false
  });

  if (projectId.value) {
    await getProjectMaster()
  }
  await getSaleOrder(route.params.soId);
});

watch(() => projectId.value, (newValue, oldValue) => {
  if (newValue) {
    getProjectMaster()
  }
})

const getProjectMaster = async () => {
  const res = await fetchProjectForm(projectId.value, getQuery());
  if (res.status) {
    if (res.data.business_location) {
      warehouses.value.push(res.data.business_location)
    }

    formData.value = res.data;
    await getAccountTree();
  }
  await getProjectGroups();
}

const getSaleOrder = (id) => {
  const companyQuery = `?company_id=${route.params.companyId}`;

   fetchSalesOrderById(id, companyQuery).then((res) => {
    if (!res.data) return;

    selectedSO.value = res.data

    formData.value.party_id = selectedSO.value.contact.id
    formData.value.sales_order_master_id = selectedSO.value.id
    formData.value.booked_sale_generals = route.query.seletedItems

    const serviceNames = selectedSO.value.general
        .filter(item => route.query.seletedItems.includes(item.id.toString())) // Convert item.id to string for comparison
        .map(item => item.product.name);

    getServiceNames.value = serviceNames.join(', ');

    if (!projectId.value) {
      formData.value.name = `${selectedSO.value.contact.name}-${serviceNames.join('-')}`
    }
  }).catch((err) => {
    console.log("Something went wrong");
  })
}

</script>
